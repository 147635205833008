// SPDX-FileCopyrightText: 2019, 2020 Andrew Hayzen <ahayzen@gmail.com>
//
// SPDX-License-Identifier: MPL-2.0

/*! This Executable Form is subject to Mozilla Public License, v. 2.0.
 *! The Source Code Form is available at https://gitlab.com/ahayzen/stringer-builders.co.uk
 *! Copyright 2019, 2020 Andrew Hayzen <ahayzen@gmail.com> */

const enum Direction {
    Left = -1,
    Right = 1,
}

const enum Rotation {
    Up = -1,
    Flat = 0,
    Down = 1,
}

class Landie {
    private bumpSize: number = 0;
    private direction: Direction = Direction.Right;
    private landie: CSSStyleRule | null = null;
    private landieSize: [number, number] = [0.0, 0.0];
    private position: number = 0;
    private rotation: Rotation = Rotation.Flat;

    private static maxBumpSize: number = 2;
    private static speed: number = 10;

    constructor(landieSelector: string) {
        // Find the landie with the given selector
        // note that this assumes that CSSStyleRule never changes
        this.landie = this.findLandie(landieSelector);

        if (this.landie) {
            // Extract the size of the landie from the background size property
            const size: string[] = this.landie.style.backgroundSize.split(' ', 2);
            this.landieSize = [parseFloat(size[0]), parseFloat(size[1])];

            // Load starting position of the landie
            this.position = parseFloat(this.landie.style.backgroundPositionX);
        }
    }

    private findLandie(selector: string): CSSStyleRule | null {
        for (let i = 0; i < document.styleSheets.length; i++) {
            let styleSheet: StyleSheet = document.styleSheets[i];

            if (styleSheet instanceof CSSStyleSheet) {
                for (let j = 0; j < styleSheet.cssRules.length; j++) {
                    let cssRule = styleSheet.cssRules[j];

                    if (cssRule instanceof CSSStyleRule && cssRule.selectorText == selector) {
                        return cssRule;
                    }
                }
            }
        }

        return null;
    }

    private generateRandomBump(): number {
        // Generate a random number between -max -> max
        // This weights halve of the bumps to being <= 0
        return Math.floor(Math.random() * (Landie.maxBumpSize * 2)) - Landie.maxBumpSize;
    }

    private generateTransformString(): string {
        if (this.direction == Direction.Left) {
            return "rotate(" + -(this.rotation as number) + "deg)";
        } else {
            return "rotate(" + (this.rotation as number) + "deg)";
        }
    }

    private generateUrlString(): string {
        // We can't flip the background image with scaleX(-1) as this breaks other calculations
        // so for now have a flipped image which we swap to
        if (this.direction == Direction.Left) {
            return "url('/assets/images/layout/landie_reversed.png')";
        } else {
            return "url('/assets/images/layout/landie.png')";
        }
    }

    public iterate(): void {
        // Iterate properties
        [this.rotation, this.bumpSize] = this.iterateRotation(this.iterateBumpSize());
        this.direction = this.iterateDirection();
        this.position = this.iteratePosition();

        // Generate transform string and calculate origin (centre of landie) for rotation
        if (this.landie) {
            this.landie.style.transform = this.generateTransformString();
            this.landie.style.transformOrigin = (this.position + (this.landieSize[0] / 2)) + "px "
                + (Landie.maxBumpSize + -this.bumpSize + (this.landieSize[1] / 2)) + "px";

            // Update the landie's position
            this.landie.style.backgroundPositionX = this.position + "px";
            this.landie.style.backgroundPositionY = (Landie.maxBumpSize + -this.bumpSize) + "px";

            // Update the direction of the background image
            this.landie.style.backgroundImage = this.generateUrlString();
        }
    }

    private iterateBumpSize(): number {
        // We are already on a bump so go back down
        if (this.bumpSize > 0) {
            return 0;
        } else {
            // Ensure bump is not negative
            return Math.max(0.0, this.generateRandomBump());
        }
    }

    private iterateDirection(): Direction {
        if (this.direction == Direction.Right && this.position > (window.innerWidth + this.landieSize[0])) {
            return Direction.Left;
        } else if (this.direction == Direction.Left && this.position < -(this.landieSize[0] * 2)) {
            return Direction.Right;
        } else {
            return this.direction;
        }
    }

    private iteratePosition(): number {
        // If the window is resized we could end up a long distance offscreen, could cap position here
        return this.position += (this.direction as number) * Landie.speed;
    }

    private iterateRotation(newBumpSize: number): [Rotation, number] {
        if (newBumpSize < this.bumpSize) {
            return [Rotation.Down, newBumpSize];
        } else if (newBumpSize > this.bumpSize) {
            return [Rotation.Up, newBumpSize];
        } else {
            return [Rotation.Flat, newBumpSize];
        }
    }
}

function startLandie(landie: Landie): number {
    // Run iterate now and queue another in 500ms
    landie.iterate();

    return setInterval(function () { landie.iterate(); }, 500);
}

window.addEventListener("load", function () {
    let landie: Landie = new Landie("footer::after");
    let intervalHandle: number = startLandie(landie);

    // If the tab is hidden then stop the Landie
    // otherwise we get a jump in the animation
    // then when the tab is shown again restart the landie
    document.addEventListener("visibilitychange", function () {
        clearInterval(intervalHandle);

        if (!document.hidden) {
            intervalHandle = startLandie(landie);
        }
    });
});
